import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import Box from './box'
import { formatNumber } from '../utils/core'
import { CustomIcon, IconWrapper } from './icon'
import REST from '../utils/rest'
import Row from './row'
import TitledBox from './titled_box'

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  icon: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignSelf: 'center',
    color: 'black',
    maxWidth: '250px',
    margin: theme.spacing(1.5),
  },
  icongrid: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    color: 'black',
    maxWidth: '250px',
    margin: theme.spacing(2),
  },
  row: {
    justifyContent: 'center',
  },
}))

export default function ImpactTracker(props) {
  const [organizations, setOrganizations] = React.useState(0)
  const [netNewSupporters, setNetNewSupporters] = React.useState(0)
  const [raised, setRaised] = React.useState(0)
  const [supporters, setSupporters] = React.useState(0)

  React.useEffect(() => {
    REST.post('insights/impact').then(data => {
      setSupporters(data.supporters_all)
      setOrganizations(data.organizations)
      setNetNewSupporters(data.supporters_new)
      setRaised(data.raised)

      /**
       * ALL DATA
       *  data.campaigns
       *  data.organizations
       *  data.raised
       *  data.sold
       *  data.supporters_all
       *  data.supporters_new
       *  data.supporters_recurring
       */
    })
  }, [])

  const classes = useStyles(props)
  function plural(number, unit) {
    if (number === 1) return unit
    return `${unit}s`
  }

  return (
    <TitledBox className={classes.box}>
      <Row className={classes.row}>
        <Grid className={classes.grid}>
          <Grid item className={classes.icongrid}>
            <CustomIcon
              className={classes.icon}
              icon={new IconWrapper('dollar')}
            />
            <Box
              title={formatNumber(raised, { isCurrency: true })}
              subtitle={'Raised'}
            />
          </Grid>
          <Grid item className={classes.icongrid}>
            <CustomIcon
              className={classes.icon}
              icon={new IconWrapper('heart')}
            />
            <Box
              title={organizations}
              subtitle={plural(organizations, 'Cause')}
            />
          </Grid>
        </Grid>
        <Grid className={classes.grid}>
          <Grid item className={classes.icongrid}>
            <CustomIcon
              className={classes.icon}
              icon={new IconWrapper('group')}
            />
            <Box
              title={supporters}
              subtitle={plural(supporters, 'Supporter')}
            />
          </Grid>
          <Grid item className={classes.icongrid}>
            <CustomIcon
              className={classes.icon}
              icon={new IconWrapper('newPeople')}
            />
            <Box
              title={`+${netNewSupporters}`}
              subtitle={plural(netNewSupporters, 'Net New Supporter')}
            />
          </Grid>
        </Grid>
      </Row>
    </TitledBox>
  )
}
