import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { CustomIcon, IconWrapper } from './icon'

import { Grid } from '@material-ui/core'

import Box from './box'
import Row from './row'

const useStyles = makeStyles(theme => ({
  grid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: 'inital',
    margin: 5,
    display: 'felx',
    felxWrap: 'wrap',
    flexDirection: 'row',
    alignSelf: 'self-start',
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    color: 'black',
    maxWidth: '250px',
  },
  row: {
    justifyContent: 'center',
  },
}))

export default function ImpactTracker(props) {
  const classes = useStyles(props)

  return (
    <Grid className={classes.grid}>
      <Row className={classes.row}>
        <Grid item className={classes.icon}>
          <CustomIcon
            className={classes.icon}
            icon={new IconWrapper('thumbup')}
          />
          <Box
            className={classes.box}
            title="Free"
            subtitle="Made to Uplift has a 0% platform fee, sustained by your generosity."
          />
        </Grid>
        <Grid item className={classes.icon}>
          <CustomIcon
            className={classes.icon}
            icon={new IconWrapper('lightening')}
          />
          <Box
            className={classes.box}
            title="Easy"
            subtitle="Making an impact has never been easier, just a few taps away."
          />
        </Grid>
        <Grid item className={classes.icon}>
          <CustomIcon className={classes.icon} icon={new IconWrapper('lock')} />
          <Box
            className={classes.box}
            title="Secure"
            subtitle="Every campaign vetted with industry-leading due diligence."
          />
        </Grid>
      </Row>
    </Grid>
  )
}
