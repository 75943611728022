import _ from 'lodash'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

import { formatNumber } from '../../utils/core'
import Status from '../status'
import Colors from '../../utils/colors'
import { CustomIcon, IconWrapper } from '../icon'
import Window from '../../utils/window'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'initial',
  },
  card: {
    display: 'flex',
    backgroundColor: '#faf1e6',
    borderRadius: 20,
    padding: theme.spacing(1),
    boxShadow: '0 0 20px 2px rgba(237, 176, 130, 0.2)',
  },
  image: {
    width: 151,
    backgroundSize: 'contain',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  content: {
    flex: '1 0 auto',
  },
  icon: {
    margin: theme.spacing(1),
  },
  status: {
    display: 'inline-flex',
  },
}))

function CampaignMiniCard({ key, campaign }) {
  const classes = useStyles()

  return (
    <Button
      className={classes.button}
      onClick={() => {
        Window.navigate(`../campaign/?id=${campaign.ID}`)
      }}
    >
      <Card key={key} className={classes.card}>
        <CardMedia
          className={classes.image}
          image={campaign.mainImage}
          title={_.get(campaign, ['product', 'name'])}
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {campaign.title}
              <CustomIcon
                className={classes.icon}
                icon={new IconWrapper('launch')}
                color="blueGrey"
              />
            </Typography>
            <Typography
              variant="button"
              color="textSecondary"
              className={classes.status}
            >
              <Status
                color={Colors.get(
                  _.get(campaign, ['status', 'color', 'mui']),
                  'A700'
                )}
                pulse={true}
              />
              {_.get(campaign, ['status', 'name'])}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Benefitting {_.get(campaign, ['organization', 'name'])}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <b>{formatNumber(campaign.raised, { isCurrency: true })}</b>{' '}
              raised by{' '}
              <b>{formatNumber(_.get(campaign, ['supporters', 'all']))}</b>{' '}
              supporters
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Button>
  )
}

export default CampaignMiniCard
