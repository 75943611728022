import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import CampaignMiniCard from '../components/campaign/mini_card'
import HowWeWork from '../components/howwework'
import Testimonial from '../components/testimonial'
import Impact from '../components/impact_tracker'
import Page from './page'
import Row from '../components/row'
import REST from '../utils/rest'
import Typed from 'react-typed'
import Campaign from '../backend/campaign'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  sections: {},

  hero: {
    width: '100vw',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),

    backgroundImage: 'url(https://i.imgur.com/TDrhSIO.png)',
    backgroundSize: 'cover',
  },
  typedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    textAlign: 'center',
  },
  heroHeader: {
    fontFamily: 'Rubik',
    fontWeight: 800,
    fontSize: 'xxx-large',
    color: 'black',
    maxWidth: '500px',
  },
  typedAnimation: {
    fontSize: '1.4rem',
    fontFamily: 'Karla',
  },
  image: {
    maxWidth: '400px',
    width: '95vw',
    minWidth: '250px',
    justifyContent: 'end',
    margin: theme.spacing(1),
  },

  mission: {
    width: '100vw',
    backgroundColor: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    fontFamily: 'Rubik',
    fontWeight: 800,
    fontSize: 'x-large',
  },
  type: {
    display: 'flex',
    fontFamily: 'Karla',
    fontSize: 'large',
    maxWidth: '550px',
  },
  right: {
    float: 'right',
    maxWidth: '600px',
  },
  impact: {
    width: '100vw',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  typography: {
    margin: theme.spacing(3),
  },
  liveCamp: {
    display: 'flex',
    flexDisplay: 'wrap',
    justifyContent: 'center',
    maxWidth: '1500px',
    width: '95vw',
  },
  campCard: {
    maxWidth: '100vw',
  },
  campaigns: {
    width: '100vw',
    backgroundColor: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },

  howwework: {
    width: '100vw',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },

  testimonial: {
    width: '100vw',
    backgroundColor: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
}))

function Home() {
  const classes = useStyles()

  const [campaigns, setCampaigns] = React.useState([])

  React.useEffect(() => {
    REST.post('campaigns/live').then(data => {
      setCampaigns(
        _.map(data.campaigns, individualCampaign => {
          return new Campaign(individualCampaign)
        })
      )
    })
  }, [])

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid item className={classes.sections}>
          <Grid className={classes.hero}>
            <Grid className={classes.typedContainer}>
              <Typography className={classes.heroHeader}>
                Data Driven Growth for Social Causes
              </Typography>
              <Typed
                className={classes.typedAnimation}
                strings={[
                  'Made for Communities',
                  'Made for Social Causes',
                  'Made to Uplift ',
                ]}
                typeSpeed={80}
              />
            </Grid>
            <Grid>
              <img
                src="https://i.imgur.com/IHYxPbf.png"
                alt="team work"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid className={classes.campaigns}>
            <Grid>
              <Typography className={classes.subtitle}>
                Live Campaigns
              </Typography>
              {_.map(campaigns, campaign => {
                return (
                  <Row className={classes.liveCamp}>
                    <div key={campaign.ID}>
                      <CampaignMiniCard
                        className={classes.campCard}
                        campaign={campaign}
                      />
                    </div>
                  </Row>
                )
              })}
            </Grid>
          </Grid>
          <Grid className={classes.impact}>
            <Grid className={classes.typography}>
              <Typography className={classes.subtitle}>
                Since our founding in June 2021
              </Typography>
              <Typography className={classes.type}>
                our impact has contributed to
              </Typography>
            </Grid>
            <Grid>
              <Impact />
            </Grid>
          </Grid>
          <Grid className={classes.mission}>
            <Grid>
              <img
                src="https://i.imgur.com/9BfUcr1.png"
                alt="team work"
                className={classes.image}
              />
            </Grid>
            <Grid>
              <Typography className={classes.subtitle}>Mission</Typography>
              <br />
              <Typography className={classes.type}>
                We promote collective and sustained activism by empowering
                resource-strapped social causes. 100% of proceeds delivered
                directly to the cause!
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.howwework}>
            <Grid>
              <Typography className={classes.subtitle}>How We Work</Typography>
              <Typography className={classes.type}>
                The most effective fundraising platform for social causes
              </Typography>
            </Grid>
            <Grid>
              <HowWeWork />
            </Grid>
          </Grid>
          <Grid className={classes.testimonial}>
            <Testimonial />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Home)
